<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3>Editar Grupo de Atributos</h3>
      </CCardHeader>
      <CCardBody>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
              class="contact-form"
              method="post"
              v-on:submit.prevent="handleSubmit(actualizar)"
          >
            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Grupo:
                  </label>
                  <validation-provider
                      name="Grupo"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <input
                        type="text"
                        v-model="registro.grupo"
                        class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Tipo:
                  </label>
                  <validation-provider
                      name="Tipo"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <selectTiposGrupos @ActualizandoId="setTiposGrupos_id" :tipoId="tipoId"/>
                    <input type="hidden" v-model="registro.tipo"/>
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Categoria:
                  </label>
                  <validation-provider
                      name="Categoria"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <selectCategoria @ActualizandoId="setCategoria" :categoriaId="categoriaId"/>
                    <input type="hidden" v-model="registro.eco_categoria_id"/>
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <div class="form-group">
              <button type="button" class="btn btn-primary mr-2" @click="actualizar()">
                <i class="fa fa-refresh" aria-hidden="true"></i>
                Actualizar
              </button>
              <router-link to="/ecoAtributosGrupos/List">
                <CButton color="secondary">Cancelar</CButton>
              </router-link>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectCategoria from '@/components/SelectCategoria.vue'
import selectTiposGrupos from '@/components/SelectTiposGrupos.vue'

export default {
  components: {
    selectCategoria,
    selectTiposGrupos,
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {},
      categoriaId: '',
      tipoId: '',
    };
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      this.id = this.$route.params.id;
      if (this.id !== "0") {
        const url ="eco-atributos-grupos/" + this.id;
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];
        this.categoriaId = this.registro.eco_categoria_id.toString();
        this.tipoId = this.registro.tipo;
      }
    },
    async actualizar() {
      try {

        const url ="eco-atributos-grupos/" + this.id;
        let response = await httpService.put(url, this.registro);
        this.$vToastify.success("Operación realizada con éxito", "Éxito");
        this.$router.back()
      } catch (error) {
        console.log(error);
      }
    },
    setCategoria(value) {
      this.registro.eco_categoria_id = value;
    },
    setTiposGrupos_id(value) {
      this.registro.tipo = value;
    }
  },
  mounted() {
    this.getRegistro();
  }
};
</script>